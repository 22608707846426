import classNames from 'classnames';
import React from 'react';

const Form = (props) => {
  const { children, className, ...rest } = props;

  return (
    <form className={classNames('w-full', className)} {...rest}>
      {children}
    </form>
  );
};

export default Form;
