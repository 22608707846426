import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { BsEye } from 'react-icons/bs';
import { HiEyeOff } from 'react-icons/hi';

import { DateTimepicker } from '../DatePicker';
import Radio from '../Radio';

const FormInput = (props) => {
  const {
    type,
    invalid,
    form,
    disabled,
    textArea,
    name,
    validate,
    style,
    label,
    hasWrapper,
    errorMessage,
    right,
    className,
    values,
    ...rest
  } = props;
  const [pwInputType, setPwInputType] = useState('password');
  const isInvalid = useMemo(() => {
    let validate = false;

    if (typeof invalid === 'boolean') {
      validate = invalid;
    }
    return validate;
  }, [form, invalid, name]);

  const inputDefaultClass = 'input';

  const inputClass = classNames(
    inputDefaultClass,
    disabled && 'input-disabled',
    isInvalid && 'input-invalid',
    textArea && 'input-textarea text-sm',
    !label ? 'mt-0' : 'mt-2',
  );

  const inputProps = {
    className: inputClass,
    disabled,
    type,
    ...rest,
  };

  const renderTextArea = (
    <textarea
      {...(form &&
        form.register(name, typeof validate === 'object' && validate))}
      style={style}
      {...inputProps}
    />
  );

  const onPasswordVisibleClick = (e) => {
    e.preventDefault();
    const nextValue = pwInputType === 'password' ? 'text' : 'password';
    setPwInputType(nextValue);
  };

  const renderInput = (
    <div className="relative">
      {type === 'date' ? (
        <DateTimepicker
          className="mt-2"
          size="sm"
          placeholder={label}
          value={form.getValues(name) ? new Date(form.getValues(name)) : null}
          onChange={(date) => {
            form.setValue(name, date);
            form.trigger(name);
          }}
        />
      ) : type === 'radio' ? (
        <Radio.Group
          name={name}
          disabled={disabled}
          value={form.getValues(name)}
          className="mt-1 flex w-full flex-col gap-3"
          onChange={(e) => {
            form.setValue(name, e);
            form.trigger(name);
          }}
        >
          {values?.map((value, i) => (
            <Radio
              key={i}
              value={value?.value}
              className="flex w-full items-center justify-between rounded-lg border border-gray-100 p-4 text-sm"
            >
              <div className="flex w-full flex-col">
                <span className="font-medium text-gray-900">
                  {value?.label}
                </span>
                <span className="font-medium text-gray-500">
                  {value?.sublabel}
                </span>
              </div>
            </Radio>
          ))}
        </Radio.Group>
      ) : (
        <>
          <input
            disabled={disabled}
            type={type === 'password' ? pwInputType : type}
            className={`input input-sm h-10 ${inputClass} ${className}`}
            {...(form &&
              form.register(name, typeof validate === 'object' && validate))}
            {...rest}
          />
          {right && (
            <div
              className={`absolute ${
                isInvalid ? 'border-red-500' : 'border-gray-300'
              } ${
                label ? 'mt-2' : 'mt-0'
              } inset-y-0 right-0 flex h-10 w-10 items-center justify-center rounded-r-md border-y border-r bg-gray-100 text-sm`}
            >
              {right}
            </div>
          )}
          {type === 'password' && (
            <span
              className="absolute right-4 top-4 cursor-pointer text-xl"
              onClick={(e) => onPasswordVisibleClick(e)}
            >
              {pwInputType === 'password' ? <BsEye /> : <HiEyeOff />}
            </span>
          )}
        </>
      )}
    </div>
  );

  const renderChildren = () => {
    if (textArea) {
      return renderTextArea;
    } else {
      return renderInput;
    }
  };

  return (
    <div className={`w-full ${hasWrapper && 'my-2'}`}>
      {label && (
        <label className="form-label text-sm font-semibold text-gray-700">
          {label}
        </label>
      )}
      {renderChildren()}
      <div
        className={`h-fit text-sm text-red-500 ${
          errorMessage ? 'opacity-1' : 'opacity-0'
        } duration-300 ease-in-out`}
      >
        {errorMessage}
      </div>
    </div>
  );
};

FormInput.propTypes = {
  type: PropTypes.oneOf([
    'text',
    'button',
    'number',
    'hidden',
    'password',
    'email',
    'date',
    'radio',
  ]),
};

FormInput.defaultProps = {
  type: 'text',
};

FormInput.displayName = 'FormInput';

export default FormInput;
