import { FaCalendar } from 'react-icons/fa';
import dayjs from 'dayjs';
import React, { useEffect, useRef, useState } from 'react';

import { Button } from 'components/ui';
import { RangeCalendar } from 'components/ui/DatePicker';
import CalendarBase from 'components/ui/DatePicker/CalendarBase';

const CalendarToggle = ({
  dateRange,
  setDateRange,
  startTime,
  endTime,
  setStartTime,
  setEndTime,
  hasTimeFilter,
  monthView,
}) => {
  const calendarRef = useRef(null);
  const [calenderOpen, setCalenderOpen] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (calendarRef.current && !calendarRef.current.contains(event.target)) {
        setCalenderOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [calendarRef]);


  const toggleCalendar = () => {
    setCalenderOpen(!calenderOpen);
  };


  const onDateSet = (e) => {
    if (hasTimeFilter) {
      const selectedStartDate = dayjs(e[0]).format('YYYY-MM-DD');
      const selectedEndDate = e[1]
        ? dayjs(e[1]).format('YYYY-MM-DD')
        : selectedStartDate;

      setDateRange({
        startDate: selectedStartDate,
        endDate: selectedEndDate,
      });

      if (!hasTimeFilter || (startTime && endTime)) {
        setCalenderOpen(false);
      }
    } else {
      if (e[1]) {
        setDateRange({
          startDate: dayjs(e[0]).format('YYYY-MM-DD'),
          endDate: e[1] ? dayjs(e[1]).format('YYYY-MM-DD') : null,
        });

        setCalenderOpen(false);
      }
    }
  };

  return (
    <div ref={calendarRef} className="relative">
      <Button
        type="button"
        className="h-9 !py-1 text-gray-700"
        onClick={toggleCalendar}
        icon={<FaCalendar />}
      >
      {`Select ${monthView ? "Month" : "DateRange" }`}
      </Button>
      {calenderOpen && (
        <div className="absolute right-0 top-10 z-10 rounded-lg border border-gray-200 bg-white p-3">
          {setDateRange && <RangeCalendar
            onChange={onDateSet}
            dateViewCount={1}
            onlyCalender
            inputSuffix={<FaCalendar className="text-xl" />}
          />}
          {monthView && (
           <CalendarBase  monthPicker = {{setCalenderOpen, setMonthPicker:monthView.setMonthPicker }}  />
          )}
          {hasTimeFilter && (
            <div className="mt-3 flex justify-between">
              <input
                type="time"
                step="1"
                value={startTime}
                onChange={(e) => setStartTime(e.target.value)}
                className="button radius-round h-9 border border-gray-300 bg-white px-3 py-2 text-sm text-gray-700 hover:bg-gray-50"
              />
              <input
                type="time"
                step="1"
                value={endTime}
                onChange={(e) => setEndTime(e.target.value)}
                className="button radius-round h-9 border border-gray-300 bg-white px-3 py-2 text-sm text-gray-700 hover:bg-gray-50"
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default CalendarToggle;