import classNames from 'classnames';
import React from 'react';

const CardNew = (props) => {
  const { children, className, ...rest } = props;

  return (
    <div
      className={classNames(
        'relative rounded-lg border bg-white p-4',
        className,
      )}
      {...rest}
    >
      {children}
    </div>
  );
};

export default CardNew;
