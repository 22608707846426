import ToastWrapper, { toastDefaultProps } from './ToastWrapper';

// Default ID for the wrapper element
const defaultWrapperId = 'default';

// Map to store all wrapper elements
const wrappers = new Map();

// Helper function to convert placement to full width
function castPlacment(placement) {
  if (/\top\b/.test(placement)) {
    return 'top-full';
  }

  if (/\bottom\b/.test(placement)) {
    return 'bottom-full';
  }
}

// Function to create a new wrapper element
async function createWrapper(wrapperId, props) {
  // Get a new instance of the ToastWrapper component
  const [wrapper] = await ToastWrapper.getInstance(props);

  // Store the new wrapper element in the map
  wrappers.set(wrapperId || defaultWrapperId, wrapper);

  // Return the wrapper element
  return wrapper;
}

// Function to get a wrapper element from the map
function getWrapper(wrapperId) {
  // Check if the map is empty
  if (wrappers.size === 0) {
    return null;
  }

  // Return the wrapper element with the given ID
  return wrappers.get(wrapperId || defaultWrapperId);
}

// Function to create a new message queue
const createToastQueue = (wrapperId) => {
  // Array to store messages in the queue
  const toastQueue = [];

  // Function to add a new message to the queue
  const push = (message, options = toastDefaultProps) => {
    // Determine the ID of the wrapper element based on the placement and block options
    let id = options.placement;
    if (options.block) {
      id = castPlacment(options.placement);
    }

    // Get the wrapper element from the map
    const wrapper = getWrapper(id);

    // If the wrapper element exists, push the message to it
    if (wrapper?.current) {
      wrapper.current.push(message);
    } else {
      // Otherwise, create a new wrapper element and push the message to it
      createWrapper(id ?? '', options).then((ref) => {
        // Add the new wrapper element and message to the queue
        toastQueue.push({ ref, message });
        ref.current?.push(message);
      });
    }
  };

  // Function to remove a message from all wrapper elements
  const remove = (key) => {
    wrappers.forEach((wrapper) => wrapper.current.remove(key));
  };

  // Function to remove all messages from all wrapper elements
  const removeAll = () => {
    wrappers.forEach((wrapper) => wrapper.current.removeAll());
  };

  // Return the queue object with the push, remove, and removeAll functions
  return { push, remove, removeAll };
};

// Create the default toast queue
const defaultToastQueue = createToastQueue();

// Function to push a new message to the default toast queue
const toast = (message) => defaultToastQueue.push(message);

// Add the push, remove, and removeAll functions to the toast function
toast.push = (message, options = toastDefaultProps) => {
  // Determine the ID of the wrapper element based on the placement and block options
  let id = options.placement;
  if (options.block) {
    id = castPlacment(options.placement);
  }

  // Get the wrapper element from the map
  const wrapper = getWrapper(id);

  // If the wrapper element exists, push the message to it
  if (wrapper?.current) {
    return wrapper.current.push(message);
  }

  // Otherwise, create a new wrapper element and push the message to it
  return createWrapper(id ?? '', options).then((ref) => {
    return ref.current?.push(message);
  });
};

toast.remove = (key) => {
  wrappers.forEach((elm) => elm.current.remove(key));
};

toast.removeAll = () => {
  wrappers.forEach((elm) => elm.current.removeAll());
};

export default toast;
