import classNames from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import ReactSelect from 'react-select';

import Button from '../Buttons';

// Use the forwardRef function to pass the ref to the component
const Select = React.forwardRef(
  (
    {
      style,
      className,
      form,
      field,
      name,
      invalid,
      label,
      errorMessage,
      permission,
      tableName,
      hasWrapper,
      refetch,
      componentAs: Component = ReactSelect,
      ...rest
    },
    ref,
  ) => {
    const [isOpen, setIsOpen] = useState(false);

    const isInvalid = useMemo(() => {
      return typeof invalid === 'boolean' ? invalid : false;
    }, [invalid]);

    const selectClass = classNames(
      'select',
      'select-lg !text-sm',
      isInvalid && 'border-red-500 border-2 rounded-md',
      className,
      label ? 'mt-2' : 'mt-0',
    );

    const customStyles = useMemo(
      () => ({
        control: (provided) => ({
          ...provided,
          minHeight: '40px !important',
        }),
      }),
      [],
    );

    const customNoOptionsMessage = () => (
      <div className="flex flex-col items-center justify-center gap-2">
        <span className="text-sm">No options</span>
        {tableName && (
          <>
            <Button
              size="xs"
              type="button"
              className="custom-button mt-1 w-fit !p-1"
              onClick={() => setIsOpen(true)}
            >
              Add {_.startCase(tableName.replace('enum', ''))}
            </Button>
          </>
        )}
      </div>
    );

    return (
      <div className={`w-full ${hasWrapper && 'my-5'} mb-1`}>
        <div className="h-0 overflow-hidden opacity-0">
          {/* {tableName && (
            <EnumAdd
              tableName={tableName}
              refetch={refetch}
              isOpen={isOpen}
              setIsOpen={setIsOpen}
            />
          )} */}
        </div>
        {label && (
          <label className="form-label mb-1.5 text-sm font-semibold text-gray-700">
            {label}
          </label>
        )}
        <Component
          className={selectClass}
          noOptionsMessage={customNoOptionsMessage}
          classNamePrefix="select"
          ref={ref}
          {...field}
          {...rest}
          styles={customStyles}
        />
        <div
          className={`h-2 text-sm text-red-500 ${
            errorMessage ? 'opacity-1' : 'opacity-0'
          } duration-300 ease-in-out`}
        >
          {errorMessage}
        </div>
      </div>
    );
  },
);

Select.propTypes = {
  componentAs: PropTypes.elementType,
};

Select.displayName = 'Select';

export default Select;
