import PropTypes from 'prop-types';
import React from 'react';

const FormRow = (props) => {
  const {
    children,
    label,
    sublabel,
    title,
    hasBorder,
    hasBorderSlim,
    hasButton,
    isVertical,
    center,
    direction,
    align,
    hasEqualRowSize,
  } = props;

  // Return the component JSX with the props values
  return (
    <div
      // Conditionally set the class based on the props
      className={`flex ${isVertical ? 'flex-col' : 'flex-row gap-5'} ${
        hasBorderSlim
          ? 'mb-1 border-b pb-1'
          : hasBorder
          ? 'mb-6 border-b pb-5'
          : ''
      } relative ${align ? 'items-center' : ''} ${
        hasEqualRowSize ? 'justify-between' : ''
      } `}
    >
      <div
        // Conditionally set the class based on the props
        className={`py-1 pr-1 ${
          hasEqualRowSize ? 'basis-3/5 overflow-hidden break-all' : 'basis-1/3 '
        }`}
      >
        {/* Conditionally render the title */}
        {title ? (
          <>
            <h4 className="text-lg font-semibold text-gray-900">{label}</h4>
            <span className="text-sm font-medium text-gray-600">
              {sublabel}
            </span>
          </>
        ) : (
          <>
            <h5
              className={`text-sm font-semibold ${
                isVertical ? 'text-gray-700' : 'text-gray-900'
              }`}
            >
              {label}
            </h5>
            <span className="text-sm font-medium text-gray-600">
              {sublabel}
            </span>
          </>
        )}
      </div>
      <div
        className={`flex gap-5 py-1 ${
          hasEqualRowSize
            ? 'basis-2/5'
            : hasButton
            ? 'basis-full justify-end'
            : isVertical
            ? 'basis-full'
            : 'basis-1/2'
        } ${direction ? 'flex-col' : 'flex-row'} ${
          center ? 'items-center' : ''
        }`}
      >
        {children}
      </div>
    </div>
  );
};

// Define the prop types for the component
FormRow.propTypes = {
  label: PropTypes.string,
  sublabel: PropTypes.string,
  title: PropTypes.bool,
  hasBorder: PropTypes.bool,
  hasBorderSlim: PropTypes.bool,
  hasButton: PropTypes.bool,
  isVertical: PropTypes.bool,
  center: PropTypes.bool,
  children: PropTypes.node,
};

// Set default values for optional props
FormRow.defaultProps = {
  title: false,
  hasBorder: true,
  hasBorderSlim: false,
  hasButton: false,
  isVertical: false,
};

// Set the component display name
FormRow.displayName = 'FormRow';

export default FormRow;
