import Form from './Form';
import FormInput from './FormInput';
import FormRow from './FormRow';
// import MultipleSelect from './MultipleSelect';
import Select from './Select';

Form.Form = Form;
Form.FormRow = FormRow;
Form.FormInput = FormInput;
Form.Select = Select;
// Form.MultipleSelect = MultipleSelect;

export default Form;
