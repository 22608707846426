import { createContext } from 'react';

export const defaultConfig = {
  themeColor: 'gray',
  direction: 'ltr',
  mode: 'light',
  locale: 'en',
  primaryColorLevel: 800,
  cardBordered: true,
  panelExpand: false,
  controlSize: 'md',
  navMode: 'transparent', // themed
  layout: {
    type: 'modern',
    sideNavCollapse: false,
  },
};

export const ConfigContext = createContext(defaultConfig);

const ConfigProvider = ConfigContext.Provider;

export const ConfigConsumer = ConfigContext.Consumer;

export function useConfig() {
  return defaultConfig;
}

export default ConfigProvider;
