import classNames from 'classnames';
import React from 'react';

import { SIZES } from '../utils/constant';

const Line = (props) => {
  const { percent, size, strokeColor, children } = props;

  const progressBackgroundClass = classNames(
    'progress-bg',
    size === SIZES.SM ? 'h-1' : 'h-1',
    `bg-${strokeColor}`,
  );

  return (
    <>
      <div className="progress-wrapper">
        <div className="progress-inner">
          <div
            className={progressBackgroundClass}
            style={{ width: `${percent}%` }}
          />
        </div>
      </div>
      {children}
    </>
  );
};

export default Line;
