import classNames from 'classnames';
import { get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import ReactSelect from 'react-select';

import { useConfig } from '../ConfigProvider';
import { useForm } from '../Form/context';
import { useInputGroup } from '../InputGroup/context';
import { SIZES } from '../utils/constant';

const Select = React.forwardRef((props, ref) => {
  const {
    size,
    style,
    className,
    form,
    field,
    components,
    theme,
    invalid,
    componentAs: Component,
    ...rest
  } = props;

  const { controlSize } = useConfig();
  const formControlSize = useForm()?.size;
  const inputGroupSize = useInputGroup()?.size;

  const selectSize = size || inputGroupSize || formControlSize || controlSize;

  const isInvalid = useMemo(() => {
    let validate = false;
    if (!isEmpty(form)) {
      const { touched, errors } = form;
      const touchedField = get(touched, field.name);
      const errorField = get(errors, field.name);
      validate = touchedField && errorField;
    }
    if (typeof invalid === 'boolean') {
      validate = invalid;
    }
    return validate;
  }, [form, invalid, field]);

  const selectClass = classNames(
    'select',
    `select-${selectSize}`,
    isInvalid && 'border-red-500 border-2 rounded-md',
    className,
  );

  return (
    <Component
      className={selectClass}
      classNamePrefix="select"
      ref={ref}
      {...field}
      {...rest}
    />
  );
});

Select.propTypes = {
  size: PropTypes.oneOf([SIZES.LG, SIZES.MD, SIZES.SM]),
  componentAs: PropTypes.elementType,
};

Select.defaultProps = {
  componentAs: ReactSelect,
};

Select.displayName = 'Select';

export default Select;
