import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';

import { useConfig } from '../ConfigProvider';
import useCallbackRef from '../hooks/useCallbackRef';
import { useTabs } from './context';

const TabNav = forwardRef((props, ref) => {
  const {
    value: valueProp,
    disabled,
    className,
    icon,
    children,
    ...rest
  } = props;

  const { value, onValueChange, variant } = useTabs();
  const isSelected = valueProp === value;

  const { themeColor, primaryColorLevel } = useConfig();

  const onTabNavClick = useCallbackRef(() => {
    if (!isSelected && !disabled) {
      onValueChange(valueProp);
    }
  });

  const color = `${themeColor}-${primaryColorLevel}`;
  const tabNavClass = classNames(
    'tab-nav text-gray-900',
    variant === 'underline' && 'py-2 mr-6',
    variant === 'pill' &&
      'mr-2 flex items-center rounded-lg py-2 px-3 transition duration-300',
    isSelected && `tab-nav-active`,
    isSelected && variant === 'underline' && 'border-b-2 border-gray-500',
    isSelected && variant === 'pill' && `bg-gray-100`,
    disabled && 'tab-nav-disabled cursor-not-allowed',
    !disabled &&
      !isSelected &&
      `hover:text-${color} dark:hover:text-${themeColor}-100`,
    className,
  );

  return (
    <div
      className={tabNavClass}
      role="tab"
      aria-selected={isSelected}
      ref={ref}
      onClick={onTabNavClick}
      {...rest}
    >
      {icon && <div className="tab-nav-icon">{icon}</div>}
      {children}
    </div>
  );
});

TabNav.propTypes = {
  disabled: PropTypes.bool,
  value: PropTypes.string.isRequired,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};
TabNav.displayName = 'TabNav';

export default TabNav;
