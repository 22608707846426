import { useSelector, useDispatch } from 'react-redux';
import { get } from 'lodash';
import { setUser, initialState } from 'store/auth/userSlice';
import { apiSignIn, apiSignOut } from 'services/AuthService';
import { onSignInSuccess, onSignOutSuccess } from 'store/auth/sessionSlice';
import appConfig from 'configs/app.config';
import { useNavigate } from 'react-router-dom';
import useQuery from './useQuery';
import { setLayout } from 'store/theme/themeSlice';
import { THEME_ENUM } from 'constants/theme.constant';

function useAuth() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const query = useQuery();

  const { token, signedIn } = useSelector((state) => state.auth.session);

  const signIn = async ({ email, password }) => {
    try {
      const resp = await apiSignIn({ email, password });
      if (resp.data) {
        const {
          meta: { access_token: token },
        } = resp.data;
        dispatch(onSignInSuccess(token));
        const data = get(resp, 'data.data');
        var role = '';
        if (data) {
          role = get(data, 'role.0');
          const user = {
            avatar: '',
            user: data.name,
            authority: role.name,
            email: data.email,
            authmode: data?.auth_mode,
          };
          dispatch(
            setUser(
              user || {
                avatar: '',
                authority: ['USER'],
                email: '',
                user: '',
                authmode: '',
              },
            ),
          );
        }

        dispatch(setLayout(THEME_ENUM.LAYOUT_TYPE_MODERN));
        window.location = '/home';

        return {
          status: 'success',
          message: '',
        };
      }
    } catch (errors) {
      return {
        status: 'failed',
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };

  const handleSignOut = () => {
    dispatch(onSignOutSuccess());
    dispatch(setUser(initialState));
    navigate(appConfig.unAuthenticatedEntryPath);
  };

  const signOut = async () => {
    try {
      await apiSignOut();
      handleSignOut();
    } catch (errors) {
      handleSignOut();
    }
  };

  return {
    authenticated: token && signedIn,
    signIn,
    signOut,
  };
}

export default useAuth;
