export const DIR_RTL = 'rtl';
export const DIR_LTR = 'ltr';
export const MODE_LIGHT = 'light';
export const MODE_DARK = 'dark';
export const LAYOUT_TYPE_CLASSIC = 'classic';
export const LAYOUT_TYPE_MODERN = 'modern';
export const LAYOUT_TYPE_STACKED_SIDE = 'stackedSide';
export const LAYOUT_TYPE_DECKED = 'decked';
export const LAYOUT_TYPE_SIMPLE = 'simple';
export const LAYOUT_TYPE_BLANK = 'blank';
export const NAV_MODE_LIGHT = 'light';
export const NAV_MODE_DARK = 'dark';
export const NAV_MODE_THEMED = 'themed';
export const NAV_MODE_TRANSPARENT = 'transparent';

export const SIDE_NAV_WIDTH = 290;
export const SIDE_NAV_COLLAPSED_WIDTH = 80;
export const SPLITTED_SIDE_NAV_MINI_WIDTH = 80;
export const SPLITTED_SIDE_NAV_SECONDARY_WIDTH = 250;
export const SIDE_NAV_CONTENT_GUTTER = 'px-4';
export const LOGO_X_GUTTER = 'px-6';
export const HEADER_HEIGHT_CLASS = 'h-16';
export const PAGE_CONTAINER_GUTTER_X = 'px-4 sm:px-6 md:px-8';
export const PAGE_CONTAINER_GUTTER_Y = 'pb-4 sm:py-6 md:px-8';

export const THEME_ENUM = {
  DIR_RTL,
  DIR_LTR,
  MODE_LIGHT,
  MODE_DARK,
  LAYOUT_TYPE_CLASSIC,
  LAYOUT_TYPE_MODERN,
  LAYOUT_TYPE_SIMPLE,
  LAYOUT_TYPE_STACKED_SIDE,
  LAYOUT_TYPE_DECKED,
  LAYOUT_TYPE_BLANK,
  SIDE_NAV_WIDTH,
  SIDE_NAV_COLLAPSED_WIDTH,
  SPLITTED_SIDE_NAV_MINI_WIDTH,
  SPLITTED_SIDE_NAV_SECONDARY_WIDTH,
  HEADER_HEIGHT_CLASS,
  NAV_MODE_LIGHT,
  NAV_MODE_DARK,
  NAV_MODE_THEMED,
  NAV_MODE_TRANSPARENT,
};
