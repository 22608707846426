import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';

import { useTabs } from './context';

const TabContent = forwardRef((props, ref) => {
  const { value, children, className, ...rest } = props;

  const context = useTabs();
  const isSelected = value === context.value;

  const tabContentClass = classNames(
    'outline-0 mt-5',
    isSelected && 'h-full mt-5',
    className,
  );

  return (
    <div
      role="tabpanel"
      tabIndex={0}
      className={tabContentClass}
      ref={ref}
      {...rest}
    >
      {isSelected && children}
    </div>
  );
});

TabContent.propTypes = {
  value: PropTypes.string.isRequired,
};
TabContent.displayName = 'TabContent';

export default TabContent;
