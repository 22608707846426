import React, { forwardRef } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';

const ScrollBar = forwardRef((props, ref) => {
  const { ...rest } = props;

  return (
    <Scrollbars
      ref={ref}
      renderView={(props) => (
        <div
          {...props}
          style={{
            ...props.style,
          }}
        />
      )}
      {...rest}
    />
  );
});

ScrollBar.displayName = 'ScrollBar';

export default ScrollBar;
