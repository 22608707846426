import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Button, Skeleton } from '../ui';

/**
 * Renders a title and subtitle for a section of content.
 *
 * @param {object} props - The component props.
 * @param {string|React.ElementType} props.asElement - The HTML element or React component to use for rendering the title and subtitle.
 * @param {string|React.ReactNode} props.title - The main title of the section.
 * @param {string|React.ReactNode} [props.subtitle] - The subtitle of the section, displayed below the main title.
 * @param {object} [props.add] - Configures an "Add" button to create a new item in the section.
 * @param {string} props.add.permission - The required permission to display the "Add" button.
 * @param {string} [props.add.link] - The URL to navigate to when the "Add" button is clicked.
 * @param {Function} [props.onAddClick] - A function to call when the "Add" button is clicked.
 * @param {'xs'|'sm'|'base'|'lg'|'xl'|'2xl'} [props.size] - The font size of the title and subtitle.
 * @param {boolean} [props.marginBottom] - Whether to add margin bottom under the title and subtitle.
 * @param {React.ReactNode} [props.children] - Additional child elements to display alongside the title and subtitle.
 * @param {React.ReactNode} [props.addChildren] - Additional child elements to display alongside the "Add" button.
 */
const PageTitle = ({
  asElement: Component,
  title,
  subtitle,
  add,
  addChildren,
  onAddClick,
  forExport,
  onExportClick,
  size,
  marginBottom,
  loading = false,
  children,
  hasBorderBottom,
}) => {
  const { t } = useTranslation();

  return (
    <Component
      className={`my-4 flex items-center justify-between ${
        marginBottom ? 'mb-6 pb-5' : ''
      } ${hasBorderBottom ? 'border-b' : ''}`}
    >
      {/* Render the title and subtitle */}
      {!loading ? (
        <div>
          {/* Render the subtitle if it's present */}
          {subtitle ? (
            <>
              <h3
                className={`font-semibold text-gray-900 ${
                  size ? `text-${size}` : ''
                }`}
              >
                {children || title}
              </h3>
              <span className="font-medium text-gray-600">{subtitle}</span>
            </>
          ) : (
            // Otherwise, render only the title
            <h3 className={`font-semibold ${size ? `text-${size}` : ''}`}>
              {children || title}
            </h3>
          )}
        </div>
      ) : (
        <div>
          <Skeleton className="h-6 w-44" />
          <Skeleton className="mt-2 h-5 w-20" />
        </div>
      )}

      {/* Render the "Add" button if it's present */}
      {add && (
        <>
          {add.link ? (
            // If an "Add" link is provided, wrap the button in a Next.js Link component
            <Link to={add.link}>
              {add.name ? (
                <Button variant="solid">{add.name}</Button>
              ) : (
                <Button variant="solid">
                  {'Create'} {title}
                </Button>
              )}
            </Link>
          ) : (
            // Otherwise, render a regular button with an "onClick" handler
            <Button variant="solid" onClick={onAddClick}>
              {add.name ? add.name : `Create ${title}`}
            </Button>
          )}
        </>
      )}
      {forExport && (
        <Link onClick={onExportClick} to={forExport.link}>
          <Button variant="solid">{forExport.name}</Button>
        </Link>
      )}

      {/* Render addChildren if not null */}
      {addChildren && addChildren}
    </Component>
  );
};

// Set the default value of `asElement` prop to "div"
PageTitle.defaultProps = {
  asElement: 'div',
};

// Specify the expected prop types for `PageTitle`
PageTitle.propTypes = {
  asElement: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.object,
  ]),
  title: PropTypes.node,
  subtitle: PropTypes.node,
  add: PropTypes.shape({
    permission: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
      .isRequired,
    link: PropTypes.string,
    name: PropTypes.string,
  }),
  onAddClick: PropTypes.func,
  size: PropTypes.oneOf(['xs', 'sm', 'base', 'lg', 'xl', '2xl']),
  marginBottom: PropTypes.bool,
  children: PropTypes.node,
  addChildren: PropTypes.node,
};

export default PageTitle;
